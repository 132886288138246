import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import { PersistExpandedDeploymentsRequest, UpdateContextRequest, UserProfileDto } from "./contracts/models";

export class UserProfileApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags UserProfile
   * @name Get
   * @request GET:/api/user-profile
   * @response `200` `UserProfileDto` Success
   */
  get = (params: RequestParams = {}) =>
    this.http
      .request<UserProfileDto, any>({
        path: `/api/user-profile`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProfile
   * @name UpdateContext
   * @request POST:/api/user-profile/context
   * @response `200` `void` Success
   */
  updateContext = (data: UpdateContextRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/user-profile/context`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags UserProfile
   * @name PersistExpandedDeployments
   * @request PUT:/api/user-profile/context/expanded-deployments
   * @response `200` `void` Success
   */
  persistExpandedDeployments = (data: PersistExpandedDeploymentsRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/user-profile/context/expanded-deployments`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
}
