import { defineNuxtPlugin } from "nuxt/app";

import PrimeVue from "primevue/config";

import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import StyleClass from "primevue/styleclass";
import Ripple from "primevue/ripple";
import Badge from "primevue/badgedirective";
import Tooltip from "primevue/tooltip";
import Toolbar from "primevue/toolbar";
import Tag from "primevue/tag";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import Column from "primevue/column";
import DataTable from "primevue/datatable";


export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.globalProperties.$appState = reactive({
    theme: "lara-light-indigo",
    dark: false,
  });

  nuxtApp.vueApp.use(PrimeVue);
  nuxtApp.vueApp.component("Button", Button);
  nuxtApp.vueApp.component("InputText", InputText);
  nuxtApp.vueApp.component("InputNumber", InputNumber);
  nuxtApp.vueApp.component("Dropdown", Dropdown);
  nuxtApp.vueApp.component("Toolbar", Toolbar);
  nuxtApp.vueApp.component("Card", Card);
  nuxtApp.vueApp.component("Tag", Tag);
  nuxtApp.vueApp.component("DataTable", DataTable);
  nuxtApp.vueApp.component("Column", Column);
  
  nuxtApp.vueApp.use(ConfirmationService);
  nuxtApp.vueApp.component("ConfirmPopup", ConfirmPopup);

  nuxtApp.vueApp.directive("styleclass", StyleClass);
  nuxtApp.vueApp.directive("ripple", Ripple);
  nuxtApp.vueApp.directive("badge", Badge);
  nuxtApp.vueApp.directive("tooltip", Tooltip);
});
