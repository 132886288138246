declare global {
   interface Array<T> {
      moveToIndex(oldIndex, newIndex): void
      isEquivalent(array2): boolean
   }
   interface Number {
      toCoordinate(): number
      toHHMMSS(): string
      padLeft(): string
      toFixedLower(): string
   }

   interface String {
      capitalize(): string
      toHHMMSS(): string
      toNiceDate(): string
      toNiceDateTime(): string
      toDaysAgo(): string
      toHumanHHMMSS(): string
   }

   interface Date {
      /**
       * Converts to the exact same date but with the time zone information set as UTC 
       */
      convertToUtc(): Date
      
      /**
       * Adds a number of days to current date
       * @param {Date} days Number of days that will be added
       */
      addDays(days: number): Date
   }
}

String.prototype.capitalize = function capitalize() {
   return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.toNiceDate = function () {
   const date = new Date(this as string)

   return [    date.getDate().padLeft(),
               (date.getMonth()+1).padLeft(),
               date.getFullYear()].join('/');
}

String.prototype.toNiceDateTime = function () {
   const date = new Date(this as string)

   return [    date.getDate().padLeft(),
               (date.getMonth()+1).padLeft(),
               date.getFullYear()].join('/') +' ' +
             [date.getHours().padLeft(),
               date.getMinutes().padLeft(),
               date.getSeconds().padLeft()].join(':');
}
String.prototype.toDaysAgo = function() {
   const date = new Date(this as string)
   const now = new Date()

   const nowDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
   const srcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

   let Difference_In_Time = nowDate - srcDate;

   let Difference_In_Days = Math.floor(Difference_In_Time * 1.0 / (1000 * 3600 * 24));

   if (Difference_In_Days < 1) {
      return 'today'
   }

   if (Difference_In_Days < 2) {
      return 'yesterday'
   }

   return Difference_In_Days.toFixed(0) + ' days ago'
}

Number.prototype.padLeft = function(){
   return this.toString().padStart(2, "0");
}

String.prototype.toHHMMSS = function () {
   var sec_num = parseInt(this as string, 10); // don't forget the second param
   var hours   = Math.floor(sec_num / 3600);
   var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
   var seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));

   let hoursString = hours.toString()
   let minutesString = minutes.toString()
   let secondsString = seconds.toString()

   if (hours   < 10) {hoursString = "0"+hoursString;}
   if (minutes < 10) {minutesString = "0"+minutesString;}
   if (seconds < 10) {secondsString = "0"+secondsString;}
   
   return hoursString+':'+minutesString+':'+secondsString;
}

String.prototype.toHumanHHMMSS = function () {
   var sec_num = parseInt(this as string, 10); // don't forget the second param
   var hours   = Math.floor(sec_num / 3600);
   var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
   var seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));

   let hoursString = hours.toString()
   let minutesString = minutes.toString()
   let secondsString = seconds.toString()

   if (hours   < 10) {hoursString = hoursString;}
   if (minutes < 10) {minutesString = minutesString;}
   if (seconds < 10) {secondsString = secondsString;}
   
   let result = ""
   if (hours > 0) {
      result += hoursString + "h "
   }

   if (minutes > 0 || hours > 0) {
      result += minutesString + "m "
   }

   result += secondsString + "s"

   return result
}

Number.prototype.toHHMMSS = function () {
   var sec_num = this as number;
   
   var hours   = Math.floor(sec_num / 3600);
   var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
   var seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));

   let hoursString = hours.toString()
   let minutesString = minutes.toString()
   let secondsString = seconds.toString()

   if (hours   < 10) {hoursString = "0"+hoursString;}
   if (minutes < 10) {minutesString = "0"+minutesString;}
   if (seconds < 10) {secondsString = "0"+secondsString;}
   
   return hoursString+':'+minutesString+':'+secondsString;
}

Number.prototype.toCoordinate = function toCoordinate() {
   return Math.round(((this as number) + Number.EPSILON) * 1000000) / 1000000
}

Number.prototype.toFixedLower = function toFixedLower() {
   return (Math.floor(this as number * 100) / 100).toString()
}

Array.prototype.moveToIndex = function moveToIndex(oldIndex, newIndex) {
   if (newIndex >= this.length) {
      let k = newIndex - this.length + 1
      while (k--) {
         this.push(undefined)
      }
   }
   this.splice(newIndex, 0, this.splice(oldIndex, 1)[0])
}

Array.prototype.isEquivalent = function isEquivalent(array) {
   if (!array || array.length != this.length) return false

   const contextArray = [...this]
   const paramArray = [...array]

   const N = contextArray.length
   const M = paramArray.length

   // If lengths of array are not equal means
   // array are not equal
   if (N != M) return false

   // Sort both arrays
   contextArray.sort()
   paramArray.sort()

   for (let i = 0; i < N; i++)
      if (contextArray[i] != paramArray[i]) return false

   return true
}

Date.prototype.convertToUtc = function convertToUtc() {
   var date = new Date(Date.UTC(
      this.getFullYear(),
      this.getMonth(),
      this.getDay(),
      this.getHours(),
      this.getMinutes(),
      this.getSeconds(),
      this.getMilliseconds()));
   
   return date;
}

Date.prototype.addDays = function addDays(days: number) {
   var date = this;
   date.setDate(date.getDate() + days);
   
   return date;
}

export {}
