import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query'

import { useQueryClient } from '~/vue-query/useVueQueryClient'

export default defineNuxtPlugin(nuxtApp => {
    const queryClient = useQueryClient()
    
    const options: VueQueryPluginOptions = { queryClient }
    
    nuxtApp.vueApp.use(VueQueryPlugin, options)
})