// import AuthService, { useAuthService } from '@/auth/AuthService'
import { HttpClient as BaseHttpClient } from '../api/base/BaseHttpClient'

class HttpClient extends BaseHttpClient {
   constructor() {
      super()
      
      const config = useRuntimeConfig()

      this.instance.defaults.baseURL = config.public.apiUrl
   }
}

export default defineNuxtPlugin(nuxtApp => {
   const httpClient = new HttpClient()

   const pinia = usePinia()
   const router = useRouter()

   httpClient.instance.interceptors.request.use(config => {
      const authStore = useAuthStore(pinia)

      const { authToken } = storeToRefs(authStore)
      const controller = new AbortController();
      
      if (authToken?.value) {
         config.headers.Authorization = `Bearer ${authToken.value}`
      }
      else {
         controller.abort();
      }

      return { ...config, signal: controller.signal };
   })

   return {
      provide: {
         httpClient: httpClient
      }
   }
 })


