import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authToken: '',
  }),
  actions: {
    setToken(newToken: any) {
        this.authToken = newToken
    }
  }
})