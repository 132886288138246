import { UserProfileDto } from "~/api/contracts/models"
import { useMSAuth } from "~/composables/auth/useMSAuth";
import { useUserProfileQuery } from "~/composables/queries/useUserProfileQuery"

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (!process.server && to?.path != '/.auth/me') {
        if (to?.path == '/.auth/login/done') {
            return navigateTo('/', { external: true } )
        }
        
     /*   if (to?.path == '/login') {
            return navigateTo('/.auth/login/aad?post_login_redirect_uri=/', { external: true } )
        } */

        const msAuth = useMSAuth();
        if (to?.path == '/logout') {
            msAuth.signOut(msAuth.getAccounts()[0].homeAccountId);
            return navigateTo('/.auth/logout', { external: true } )
        }

        let user = {} as UserProfileDto

        const allowedPaths = [
            '/unauthorized',
            '/wall-dashboards',
            '/dashboard',
            '/business-goals',
            '/wall-dashboard',
            '/recommendations',
            '/firewall',
            '/whitelists',
            '/deployments',
            '/endpoints',
            '/repository-info-details',
            '/firewall-tracking-lookup'
        ]

        try 
        {
            let { data: userProfile, status, error} = useUserProfileQuery()
            // const nuxtApp = useNuxtApp()
            // appInsights.trackTrace('test m message from ' + user.email)                

            while (status.value == 'loading') {
                await new Promise(r => setTimeout(r, 1000));
            }

            if (error.value) throw new Error("Error getting user profile");

            const returnedUser = userProfile.value

            // if (user?.email) {
            //     app.$appInsights.setAuthenticatedUserContext(user.email!, user?.id, true)
            // }

            if (returnedUser && !returnedUser.hasAccess && to.path != '/unauthorized')
            {
                return navigateTo('/unauthorized', { external: true } )
            }
            else if (!returnedUser || !returnedUser.isAdmin && !(to.path == '/' || allowedPaths.some(x => to.path.endsWith(x))))
            {
                return navigateTo('/' )
            }
        }
        catch (e: any)
        {
            //debugger
            //return navigateTo('/.auth/login/aad?post_login_redirect_uri=/', { external: true } )
        }

        // else if (user.user_id.indexOf("cphmist.com") == -1 && user.user_id.indexOf("kamilkurys.com") == -1)
        // {
        //     if (from?.path != '/unauthorized' && to?.path != "/unauthorized")
        //     {
        //         return navigateTo('/unauthorized',  { replace: true } )
        //     }
        // }
    }
})