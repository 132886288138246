import { useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'
import { useUserProfileApi } from '../api/useUserProfileApi'

export function useUserProfileQuery({ enabled } = { enabled: ref(true) }) {
   const client = useUserProfileApi()
   
   return useQuery([QueryKeys.CurrentUser], () => client.get(), {
      enabled,
      staleTime: Infinity,
      refetchOnWindowFocus: false
   })
}

